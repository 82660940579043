@import url('./font.css');
@import url('./utility.css');
@import url('./responsive.css');
.head-color {
  color: #5e5873;
}

.subhead-color {
  color: #6e6b7b;
}

.FW-14 {
  font-size: 14px;
}

.FW-12 {
  font-size: 12px;
}

.FW-10 {
  font-size: 10px;
}

.vertical #bs-stepper-box {
  border-radius: 50px;
}

.bs-stepper-line {
  max-height: 75px;
  align-self: center;
  margin: unset;
}

.bs-stepper {
  position: relative;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  background: #5e72e4;
  color: white;
}

.dot {
  font-size: 10em;
  line-height: 0.25em;
}

.icon-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  padding: 0.5em 0;
  font-weight: 500;
  border-radius: 50px;
}

#card-overlay {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 2;
  cursor: wait;
  /* pointer-events:none; */
}

.input-group .form-control {
  border-left: 1px solid #cad1d7 !important;
}

.dropzone {
  padding: 20px;
  background: rgba(186, 191, 199, 0.12);
  border: 0.836139px dashed #7666f8;
  border-radius: 6px;
  color: #7367f0;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-container {
  display: inline-block;
  position: relative;
}

.rm-gallary-img {
  position: absolute;
  top: 0;
  right: 0;
}

.btn-link {
  box-shadow: none !important;
}

.order-height {
  height: 650px;
  margin-bottom: 20px;
}

.document-checklist-table {
  height: 280px !important;
  margin-bottom: 15px;
  overflow-y: scroll;
}

/* Ali custom CSS */
.custom-table-div th {
  color: #5e5873 !important;
  font-weight: 600;
  vertical-align: inherit !important;
}

.custom-table-div th .fa-chevron-up,
.custom-table-div th .fa-chevron-down {
  font-size: 9px;
  margin-left: 0.25rem;
  color: #babfc7;
  cursor: pointer;
}

.custom-table-div td img {
  border-radius: 100px;
}

.custom-table-div th .fa.active {
  color: #6e6b7b;
}

.custom-table-div td .customer-name {
  color: #5e5873;
  font-weight: 700;
}

.custom-table-div td .customer-mail {
  color: #b9b9c3;
  font-size: 10px;
}

.custom-table-div td .extra-amt {
  color: green;
}

.user-timeline-div,
.total-invst-card {
  min-height: 500px;
  height: 500px;
  overflow-y: overlay;
}

.table-responsive {
  z-index: 0;
}

.user-timeline-div::-webkit-scrollbar,
.total-invst-card .table-responsive::-webkit-scrollbar {
  width: 3.5px;
  height: 3.5px;
}

.user-timeline-div::-webkit-scrollbar-track,
.total-invst-card .table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.user-timeline-div::-webkit-scrollbar-thumb,
.total-invst-card .table-responsive::-webkit-scrollbar-thumb {
  background: rgb(160, 160, 160);
}

.user-timeline-div::-webkit-scrollbar-thumb:hover,
.total-invst-card .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.user-timeline-div .dots-div {
  margin-top: 1.8rem;
}

.user-timeline-div .dot {
  width: 10px;
  height: 10px;
  border: 4px solid rgba(255, 255, 255, 0.77);
  box-sizing: content-box;
  border-radius: 100px;
}

.user-timeline-div .blue-dot {
  background-color: #7367f0;
}

.user-timeline-div .skyblue-dot {
  background-color: #00cfe8;
}

.user-timeline-div .orange-dot {
  background-color: #ff9f43;
}

.user-timeline-div .purple-dot {
  background-color: #d63384;
}

.user-timeline-div .lower-line {
  background-color: #ebe9f1;
  height: calc(100% + 11px);
  width: 2px;
  margin-left: 7px;
}

.user-timeline-div .row:last-child .lower-line {
  height: calc(100% - 1rem);
}

.user-timeline-div p {
  margin-bottom: 0;
}

.user-timeline-div .order-status {
  color: #5e5873;
  font-size: 14px;
  font-weight: 600;
}

.user-timeline-div .order-time {
  color: #b9b9c3;
  font-size: 11px;
  font-weight: 300;
}

.user-timeline-div .order-desc {
  color: #b9b9c3;
  font-size: 14px;
  font-weight: 300;
}

.user-timeline-div .order-pdf-name {
  margin-top: 0.75rem;
}

.user-timeline-div .order-pdf-name p {
  color: #5e5873;
  font-weight: 600;
  font-size: 14px;
}

.customer-detail-div .profile-Img {
  width: 6rem;
  border-radius: 6px;
}

.customer-detail-div .user-mail {
  font-weight: 300;
  color: #6e6b7b;
}

.customer-detail-div h2 {
  font-size: 18px;
}

.customer-detail-div small {
  font-size: 12px;
  color: #b9b9c3;
}

.customer-detail-div .inner-detail .row .col:first-child {
  font-size: 14px;
  color: #5e5873;
}

.customer-detail-div .inner-detail .row .col:last-child {
  font-size: 14px;
  color: #6e6b7b;
  font-weight: 300;
}

.singleOrder-detail-card1 small {
  color: #b9b9c3;
  font-size: 12px;
  letter-spacing: 0.6px;
  font-weight: 300;
}

.singleOrder-detail-card1 img {
  background-color: rgba(0, 207, 232, 0.12);
  padding: 0.5rem;
  border-radius: 4px;
}

.singleOrder-detail-card1 img.book-amt-img {
  background-color: #ffeddc;
}

.singleOrder-detail-card1 a {
  color: #7367f0;
  background: rgba(115, 103, 240, 0.12);
  font-size: 13px;
  padding: 0.2rem 0.8rem;
  font-weight: 600;
  border-radius: 4px;
}

.legal-copies-status .upload-copy-icons .modal-btn {
  margin-right: 0.5rem;
  border: 0;
  padding: 0;
}

#sign-URL .modal-header {
  background-color: #f8f8f8;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.legal-copies-status .upload-copy-icons .fa {
  background: white;
  border: 1px solid #d8d6de;
  padding: 0.5rem;
  border-radius: 4px;
  width: 2rem;
  text-align: center;
  color: #babfc7;
}

.legal-copies-status .upload-copy-icons .fa.active {
  background: #7367f0;
  border-color: transparent;
  color: white;
}

.order-detail-doc-upload {
  height: 2.5rem;
  border-radius: 8px;
  width: 24rem;
}

.order-detail-doc-upload .browseBTN {
  background-color: #4b4b4b;
  color: white;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.7rem;
  font-size: 14px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

#OrderOverview .profile-img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100px;
}

#OrderOverview .payment-summary-div {
  background-color: #f6f6f6;
  border-radius: 8px;
}

#OrderOverview .payment-summary-div li a {
  color: #b9b9c3;
}

#OrderOverview .payment-summary-div li a.active {
  color: #7367f0;
  border: 0;
  background-color: transparent;
}

#OrderOverview .payment-summary-div .status {
  color: #7367f0;
  background: rgba(115, 103, 240, 0.12);
  font-size: 14px;
  padding: 0.1rem 0.6rem;
  font-weight: 600;
  border-radius: 4px;
}

#OrderOverview thead {
  background-color: #f3f2f7;
}

#OrderOverview thead th {
  border-bottom: 0;
}

#OrderOverview .price-break-div,
#OrderOverview .ID-div {
  background-color: #f6f6f6;
  border-radius: 8px;
}

.order-detail-nav li a {
  font-size: 14px;
  font-weight: 600;
  min-width: 11rem;
  text-align: center;
}

.order-detail-nav li a.active {
  color: #7367f0 !important;
  background-color: transparent !important;
  border: 0;
  border-bottom: 2px solid;
  border-color: #7367f0 !important;
}

/* custome css */

.share-class-text {
  text-align: center;
}

.share-class-text .share-class-digit {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 62px;
  line-height: 24px;
  /* or 39% */
  color: #000000;
}

.modal.right.fade.in .modal-dialog {
  right: 0 !important;
  transform: translateX(-50%);
}
.modal.right .modal-content {
  height: 100%;
  overflow: auto;
  border-radius: 0;
}
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal.right.fade.in .modal-dialog {
  transform: translateX(0%);
}
.modal.right.fade .modal-dialog {
  right: 0px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right .modal-header {
  background-color: #50caff;
  color: #fff;
}
.modal.right .modal-header::after {
  content: "";
  display: inline-block;
}
.modal.right .close {
  text-shadow: none;
  opacity: 1;
  color: #ff4d4d;
  font-size: 26px;
}
/*  form-control  */

.form-control {
  border-radius: 0;
  box-shadow: none;
}
.form-control:focus {
  box-shadow: none;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.share-avatar {
  border-radius: 50%;
  width: 30px;
}
.bg-grey {
  background-color: #efefef;
}

.inv-txt {
  height: 500px;
}

.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.color input {
  background-color: #f1f1f1;
}
.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #2ea591;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

.upload-btn1 {
  position: absolute;
  font-size: 50px;
  opacity: 0;
  right: 0;
  top: 0;
}
.model-btn {
  width: 180px;
}

.shareclass-chart {
  width: 500px;
  height: 400px;
}

.shareclass-chart-2 {
  width: 500px;
  height: 400px;
  margin-left: 20px;
}

.cap-table {
  width: 350px;
  height: 350px;
}

.cap-table-pbar {
  height: 350px;
}

.progress {
  height: 15px !important;
}

.company-dash {
  background: linear-gradient(46.62deg, #7367f0 0%, #9e95f5 93.64%);
  height: 100px;
  border-radius: 6px;
}

.alignleft {
  float: left;
  text-align: left;
  width: 33.33333%;
}
.aligncenter {
  font-size: 28px;
  color: #fff;
  margin: auto;
}
.aligncenter-txt {
  font-size: 14px;
  color: #fff;
  margin: auto;
}
.alignright {
  float: left;
  text-align: right;
  width: 33.33333%;
}

.img-h {
  height: 100px;
}

.card-body {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.shadow {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
  top: 80px;
  bottom: 90px;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #eeeeee;
  left: 10%;
  margin-left: -1.5px;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 100%;
  float: left;
  border-radius: 2px;
  position: relative;
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: -3%;
  left: 10%;
  margin-left: -25px;
  z-index: 100;

}

.timeline > li.timeline-inverted > .timeline-panel {
  left: 3%;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-heading {
  width: 70%;
}
.timeline-clock {
  width: 25%;
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.flex-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.flex-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.flex-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.flex-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.align-items-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.user-timeline {
  width: 500px;
}

.timeline-p {
  line-height: 0px;
  padding-top: 2px;
}

/* unsubscribe page style */


.unsubscribe-wrapper{
  height: 100vh;
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f5f6fa;
}
.unsubscribe{
  max-width:609px;
  display: flex;
  margin-left: 4px !important;
  align-items: center;
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1) !important;
}
.unsubscribe-text{
  color:#828282;
  font-size: 18px;
  font-weight:500;
}
.unsubscribed-text{
  color:#828282;
  font-size: 14px;
  font-weight:500;
}
.unsubscribe-heading{
  font-weight: 700;
  font-size: 32px;
}
.unsubscribed-heading{
  font-weight: 700;
  font-size: 24px;
}
.unsubscribe-btn{
  background-color: #725AEC;
  color: white;
  font-weight: 500 !important;
}
.unsubscribe-btn:focus{
  background-color: #725AEC !important;
  color: white !important;
}
.unsubscribe-btn:hover{
  background-color: #725AEC;
  color: white;
}

.unsubscribe-footer{
  background-color: #725AEC;
}
.unsubscribe-footer .footer-text{
  color: white;
  font-weight: 400;
  font-size:14px;
  padding-left:24px;
  padding-right: 24px;
  text-align: center;
}
.unsubscribe-footer .footer-text .footer-link{
    color: white;
    font-size: 14px;
    font-weight: 600;
}

