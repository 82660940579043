@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.montserrat-thin {
    font-family: 'Montserrat', sans-serif;
    font-weight: 100;
}

.montserrat-extra-light {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}

.montserrat-light {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

.montserrat-regular {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

.montserrat-medium {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.montserrat-semibold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.montserrat-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.montserrat-extra-bold {
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
}

.montserrat-black {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
}