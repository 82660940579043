.page-heading{
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 24px !important;
    color: #5e5873;
}
.page-thead-style{
    background-color: #f3f2f7;
}
.page-thead-style th{
    color: #5e5873 !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 600 !important;
    font-size: 12px !important;
}

.page-tbody-cell td{
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color:#6e6b7b;
    font-size: 14px;
}
.data-blue{
    color: #7367f0 !important;
}
.styled-input{
    border-radius: 5px !important;
    border: 1px solid #D8D6DE;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    color: #5E5873 !important;
    height: 43px !important;
}
.styled-input::placeholder{
    font-family: 'Montserrat', sans-serif !important;
    color:#B9B9C3 !important;
}
.styled-menu{
    border-radius: 5px !important;
    border: 1px solid #D8D6DE;
    color: #5E5873 !important;
    height: 43px !important;
}
.styled-area{
    border-radius: 5px !important;
    border: 1px solid #D8D6DE;
    color: #5E5873 !important;
}
.auth-section{
    display: flex;
    align-items: center;
    justify-content: center;
}
.copy-icon{
    display: flex;
    justify-content: center;
    align-items: center;
}
.order-list-heading{
    font-family: 'Montserrat', sans-serif;
    color:#5E5873;
    font-size: 16px;
    font-weight: 400;
}

.token-switch-label::before {
    color: #fff;
    border-color: #05cfe6 !important;
    background-color: #05cfe6 !important;
    box-shadow: none !important;
    outline: none !important;
}
.asset-switch-label::before {
    color: #fff;
    border-color: #007bff !important;
    background-color: #007bff !important;
    box-shadow: none;
    outline: none !important;
}
.seller-switch-label::after{
    background-color: white !important;
}
.fee-breakup-title{
    font-family: 'Montserrat';
    color:#5E5873;
    font-size: 24px;
    font-weight: 500,
}
.fee-filter-select{
    border: 2px solid #7367F0;
    border-radius: 5px !important;
    height: 46px !important;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat';
    color: #7367F0;
}
@media screen and (max-width:768px) {
   .ryzer-logo{
     display: none !important;
   }
   .auth-image{
    display: none !important;
   }   
   .main-content-row{
     height: 100vh !important;
     margin-left: 1rem !important;
   }
   
}
