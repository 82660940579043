@media screen and (max-width:768px) {
  .unsubscribe {
    width: 80% !important;
  }

  .unsubscribe-heading {
    font-size: 24px !important;
  }

  .unsubscribe-text {
    font-size: 14px !important;
    padding: 0px 10px !important;
  }

  .unsubscribe-footer .footer-text {
    font-size: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .unsubscribe-footer .footer-text .footer-link {
    font-size: 12px !important;
  }
}

@media screen and (max-width:576px) {
  .unsubscribed-heading {
    font-size: 18px !important;
  }

  .unsubscribed-text {
    font-size: 12px !important;
  }
  .mail-back-btn{
    margin-right: 0px !important;
  }
}

@media screen and (max-width:400px) {
  .unsubscribe-heading {
    font-size: 20px !important;
  }
  .unsubscribed-heading {
    font-size: 15.5px !important;
  }
  .unsubscribe-text {
    font-size: 12px !important;
  }
  
}

