.navbar-wrapper {
    width: 100%;
    display: flex;
    justify-content: end;

    @media screen and (max-width:767px) {
        display: none;
    }

}

.nav-top {
    width: 95% !important;
    margin-top: 1.5rem !important;
    margin-right: 32px !important;
    //box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.04) !important;
    border-radius: 6px;

    @media screen and (min-width:768px) {
        width: 91% !important;
        margin-right: 24px !important;
    }

    @media screen and (min-width:992px) {
        width: 91% !important;
        margin-right: 37px !important;
    }

    @media screen and (min-width:1200px) {
        width: 93% !important;
        margin-right: 35px !important;
    }

    @media screen and (min-width:1400px) {
        width: 95% !important;
        margin-right: 32px !important;
    }

}

.img-profile::after {
    display: none !important;
}

.user-name {
    font-size: 14px !important;
    margin-bottom: 0px !important;
    font-weight: 400 !important;
    color: #6e6b7b !important;
    line-height: 15px !important;
}

.user-role {
    margin-bottom: 0px !important;
    font-size: 12px !important;
    color: #b9b9c3 !important;
    line-height: 13px !important;
    font-weight: 400 !important;
}

.seller-text {
    color: #5e5873 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
}

.code-label {
    color: #82868b !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.code-text {
    color: #4b4b4b !important;
    font-size: 14px !important;
    font-weight: 600 !important;
}

.dropdown.no-arrow .dropdown-toggle::after {
    display: none;
}

.badge-counter {
    background-color: red !important;
    color: white !important;
    border-radius: 36px;
    position: absolute;
    transform: scale(0.8);
    transform-origin: top right;
    right: 8px;
    margin-top: -0.25rem;
}

.country-name {
    font-size: 14px !important;
    color: #6e6b7b !important;
    font-weight: 400 !important;
}

.cursor-pointer {
    cursor: pointer;
}

.wallet-button {
    box-shadow: none !important;
}

.wallet-button:focus {
    box-shadow: none !important;
    background-color: transparent !important;

}

.schedule-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98vh
}

.editAccount {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: white !important;
}

.editAccount:hover {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: white !important;
}

.editAccount:focus {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: white !important;
}

.confirm-modal-title {
    color: #5e5873;
    font-size: 18px;
}

.modal-close {
    padding: 6px 8px !important;
    position: absolute;
    right: -4px;
    top: -7px;

}

.modal-close:focus {
    background-color: white !important;
}

.empty_data {
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rz_paymemt_status_faild {
    color: #f7f7f7 !important;
    background: rgb(155, 1, 1) !important;
    font-weight: bold;
    font-size: 12px;
    padding: 10px 10px;
}
.rz_paymemt_status {
    color: #FF9F43 !important;
    background: rgba(255, 159, 67, 0.12) !important;
    font-weight: bold;
    font-size: 12px;
    padding: 10px 10px;
}

.rz_paymemt_status_success {
    color: #28C76F !important;
    background: rgba(40, 199, 111, 0.12) !important;
    font-weight: bold;
    font-size: 12px;
    padding: 10px 10px;
}

.payment_suceess_btn {
    background: #28C76F;
    color: white;
    border-radius: 4px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 15px;
}

.payment_suceess_outline {
    background: transparent;
    color: #28C76F;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 15px;
}

.rental-payout-pending {
    color: #FF9F43 !important;
    background: rgba(255, 159, 67, 0.12) !important;
    font-size: 10px;
    padding: 8px 8px;
}

.rental-payout-paid {
    color: #28C76F !important;
    background: rgba(40, 199, 111, 0.12) !important;
    font-size: 10px;
    padding: 8px 8px;
}

.rental-payout-hold {
    color: #EA5455 !important;
    font-size: 10px;
    background-color: rgba(234, 84, 85, 0.12) !important;
    padding: 8px 8px;
}

.all-page-heading {
    font-family: 'Montserrat';
    color: #5E5873;
    font-size: 24px;
    font-weight: 500
}

.rz-heading {
    font-family: 'Montserrat';
    color: #5E5873;
    font-weight: 500
}

.heading-style-1 {
    font-family: 'Montserrat';
    color: #4B4B4B;
    font-size: 24px;
    font-weight: 500
}

.rz-thead-style {
    background-color: #D8D6DE !important;
}

.rz-thead-style th {
    color: #6E6B7B !important
}

.rz-shadow-none {
    box-shadow: none !important;
}

.rz-shadow {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1) !important;
}

.rz-card-border {
    border: 1px solid #BABFC7 !important;
}

.currency-symbol {
    position: absolute;
    left: 40px;
    color: #82868B;
    font-family: Arial, Helvetica, sans-serif !important;
    border-right: 2px solid #EBE9F1;
    padding-right: 11px;
}

.remove-cashflow-col {
    position: absolute;
    top: -20px;
    left: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}

.cashflow-col-wrapper {
    .remove-cashflow-col-icon {
        display: none;
    }

    &:hover {
        .cashflow-col {
            background: #EA54551A
        }

        .remove-cashflow-col-icon {
            display: block !important;
        }
    }

    ;
}

.custom-file-input~.custom-file-label::after {
    background: #7367F0 !important;
    font-family: 'Montserrat' !important;
    font-weight: 500 !important;
    color: white !important;
}

.legal-main {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.rz-active-page {
    a {
        background-color: #7367F0 !important;
        color: white !important;
    }


}


.custom-control-input:checked ~ .rz-custom-label::before{
    background-color: #09A57F !important;
    cursor: pointer !important;
}
#about-property{
    color: #5e5873 !important;
}

@media (min-width: 10px) {
    .legal-main {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}