.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.align-center {
    display: flex;
    align-items: center;
}

.rz-text-18 {
    font-size: 18px !important;
}

.rz-text-22 {
    font-size: 22px !important;
}

.rz-text-20 {
    font-size: 20px !important;
}

.rz-heading-18 {
    color: #5E5873;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 18px;
}

.rz-heading-16 {
    color: #5E5873;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 16px;
}