.ql-snow .ql-size-small {
  font-size: 12px !important;
}

.ql-snow .ql-size-large {
  font-size: 18px !important;
}

.ql-snow .ql-size-huge {
  font-size: 24px !important;
}
