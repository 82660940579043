.rz-button-primary {
    background-color: #7367F0 !important;
    color: white !important;
    padding-left: 1.5rem;
    cursor: pointer;
    padding-right: 1.5rem;
    font-family: $montserrat !important;
    font-weight: 500 !important;
    box-shadow: none !important;
}

.rz-button-outline-primary {
    color: #7367F0 !important;
    border: 1px solid #7367F0 !important;
    background-color: white;
    padding-left: 1.5rem;
    cursor: pointer;
    padding-right: 1.5rem;
    box-shadow: none !important;
    font-family: $montserrat !important;
    font-weight: 500 !important;
}

.rz-button-outline-primary:hover {
    background-color: #7367F0 !important;
    color: white !important;
}
.rz-button-danger{
    color: white !important;
    border: 1px solid #EA5455 !important;
    background-color: #EA5455;
    padding-left: 1.5rem;
    cursor: pointer;
    padding-right: 1.5rem;
    box-shadow: none !important;
    font-family: $montserrat !important;
    font-weight: 500 !important;
}
.rz-button-outline-danger {
    color: #EA5455 !important;
    border: 1px solid #EA5455 !important;
    background-color: white;
    padding-left: 1.5rem;
    cursor: pointer;
    padding-right: 1.5rem;
    box-shadow: none !important;
    font-family: $montserrat !important;
    font-weight: 500 !important;
}

.rz-button-outline-danger:hover {
    background-color: #EA5455 !important;
    color: white !important;
}


.rz-button-success {
    background-color: #28C76F !important;
    color: white !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-family: $montserrat !important;
    font-weight: 500 !important;
    box-shadow: none !important;
}

.rz-button-outline-success {
    color: #28C76F !important;
    border: 1px solid #28C76F !important;
    background-color: white;
    padding-left: 1.5rem;
    cursor: pointer;
    padding-right: 1.5rem;
    box-shadow: none !important;
    font-family: $montserrat !important;
    font-weight: 500 !important;
}

.rz-button-outline-success:hover {
    background-color: #28C76F !important;
    color: white !important;
}