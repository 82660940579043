@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Montserrat-100{
    font-family: $montserrat;
    font-weight: 100 !important;
}
.Montserrat-200{
    font-family: $montserrat;
    font-weight: 200 !important;
}
.Montserrat-300{
    font-family: $montserrat;
    font-weight: 300 !important;
}
.Montserrat-400{
    font-family: $montserrat;
    font-weight: 400 !important;
}
.Montserrat-500{
    font-family: $montserrat;
    font-weight: 500 !important;
}
.Montserrat-600{
    font-family: $montserrat;
    font-weight: 600 !important;
}
.Montserrat-700{
    font-family: $montserrat;
    font-weight: 700 !important;
}
.Montserrat-800{
    font-family: $montserrat;
    font-weight: 800 !important;
}
.Montserrat-900{
    font-family: $montserrat;
    font-weight: 900 !important;
}